
import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";
import { IBlog } from "@/models/IBlog";
import { BlogRequest } from "@/models/IBlogSaveRequest";
import { IResponse } from "@/models/IResponse";
import auhtHeaderForUpload from "./auth-header-upload";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;

class BlogService {
  blog!: IBlog;
  lstBlog: IBlog[] = [];
  responsedata: any;

  saveBlog(model: BlogRequest, key: string): Promise<IResponse> {


    this.responsedata = axios
      .post<IResponse>(API_URL + "blog/saveblog", model, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  deleteBlog(blogId:number, key: string): Promise<IResponse> {
    this.responsedata = axios
      .post<IResponse>(API_URL + "blog/deletepage",  {Id:blogId}, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  uploadBlogImage(model: any, key: string): Promise<IResponse> {

    this.responsedata = axios
      .post<IResponse>(API_URL + "blog/uploadfiles", model, {
        headers: auhtHeaderForUpload(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getListofBlog(
    pageIndex: number,
    key:string,
    search: string = "",
  ): Promise<Result> {
    var URL =
      API_URL + "blog/getallblogs/" + pageIndex;

    if (search.length > 0) {
      URL =
        API_URL +
        "blog/getallblogs/" +
        pageIndex +
        "?search=" +
        search;
    }

    this.responsedata = axios
      .get<IPageResponse>(URL, { headers: auhtHeader(key) })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getBlogByBlogId(blogId: number, key:string): Promise<IBlog> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL +
          "blog/getblogbyblogId?blogId=" +
          blogId,
        { headers: auhtHeader(key) }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
}

export default new BlogService();
