import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { IPage } from "@/models/IPage";
import WebPageService from "@/services/WebPageService";
import { Watch } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { VueEditor } from "vue2-editor";
import Multiselect from "vue-multiselect";
import { IBlog } from "@/models/IBlog";
import BlogService from "@/services/BlogService";
import CategoryService from "@/services/CategoryService";
import { BlogRequest } from "@/models/IBlogSaveRequest";
import { namespace } from "vuex-class";

const Auth = namespace("Auth");

@Options({
  components: {
    AdminLayout,
    ValidationObserver,
    VueEditor,
    Multiselect,
  },
})
export default class BlogModule extends Vue {
  active = false;
  loading = false;
  isloaded = false;
  blogs: any;
  totalItems = 0;
  current_page = 1;
  search: string = "";
  pageHeading: string = "";
  totalKey = 0;
  totalcharacterDis = 0;
  isPageName = true;
  pagename = "";
  refForm: any = (this as any).$refs.form;
  categories: any = [];
  value: any = {};
  isFailed = false;
  failedMessage = "";
  pageContent = "";
  tags: any = [];
  isPost = true;
  post = "";
  istagError = false;
  tagError = "";
  currentKey = "";
  files = "";
  activePrompt = false;
  acceptAlert = false;
  pageId = 0;
  blogId = 0;

  blogImage: any = [];
  blogRequest: BlogRequest = {
    blog: {
      blogid: 0,
      categoryid: 0,
      category: "",
      title: "",
      post: "",
      minimumPost: "",
      ispublished: true,
      metatags: "",
      timstamp: "",
      slug: "",
      ispost: false,
      blogMinimumPost: "",
      metatag: "",
      url: "",
      tags: [],
      metaKey: "",
      metaDiscription: "",
    },
    tag: [],
  };

  $refs!: {
    validationProviderRef: InstanceType<typeof ValidationProvider>;
    validationObserverRef: InstanceType<typeof ValidationObserver>;
  };

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 3,
    totalElements: 0,
  };

  blog: IBlog = {
    blogid: 0,
    categoryid: 0,
    category: "",
    title: "",
    post: "",
    minimumPost: "",
    ispublished: true,
    metatags: "",
    timstamp: "",
    slug: "",
    ispost: false,
    blogMinimumPost: "",
    metatag: "",
    url: "",
    tags: [],
    metaKey: "",
    metaDiscription: "",
  };

  @Auth.Getter
  private getBranchId!: string;

  addTag(event) {
    event.preventDefault();
    var val = event.target.value.trim();
    console.log(val);
    if (val.length > 0) {
      this.tags.push(val);
      event.target.value = "";
    }

    console.log(JSON.stringify(this.tags));
  }

  removeTag(index) {
    this.tags.splice(index, 1);
  }

  removeLastTag(event) {
    if (event.target.value.length === 0) {
      this.removeTag(this.tags.length - 1);
    }
  }

  openConfirm(blogId: number) {

    this.blogId = blogId;
    this.activePrompt = true;
  }

  deleteBlog(blogId: number) {
    (this as any).$Progress.start();

    BlogService.deleteBlog(this.blogId, this.getBranchId).then(
      (data) => {
        this.loading = false;
        if (data.statusCode == 200) {
          this.setTable(1);
          (this as any).$vs.notify({
            title: "Blogs",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
        } else {
          this.isFailed = true;
          this.failedMessage = data.message;
          (this as any).$vs.notify({
            title: "Blogs",
            text: data.message,
            color: "danger",
            icon: " highlight_off",
            time: 6000,
          });
          (this as any).$Progress.finish();
        }
        (this as any).$refs.observer.reset();
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "Blog Category",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }

  async created() {
    (this as any).$Progress.start();
    var result = await BlogService.getListofBlog(1, this.getBranchId);
    this.blogs = result.items;
    this.isloaded = true;
    this.totalItems = result.totalPages;
    var user = JSON.parse(localStorage.getItem("user") || "{}");
    this.categories = await CategoryService.getListofCategory(this.getBranchId);
    (this as any).$Progress.finish();
  }

  @Watch("getBranchId", { deep: true })
  async onBranchPropertyChanged(value: string, oldValue: string) {
    (this as any).$Progress.start();
    var result = await BlogService.getListofBlog(1, this.getBranchId);
    this.isloaded = false;
    this.blogs = result.items;
    this.totalItems = result.totalPages;
    this.isloaded = true;
    var user = JSON.parse(localStorage.getItem("user") || "{}");
    this.categories = await CategoryService.getListofCategory(this.getBranchId);
    (this as any).$Progress.finish();
  }

  handleChangePage(page) {
    this.table.page = page - 1;
    this.isloaded = false;
    this.setTable(this.table.page);
  }

  async setTable(pagenumber: number) {
    (this as any).$Progress.start();
    this.blogs = [];
    var result = await BlogService.getListofBlog(pagenumber, this.search);
    this.isloaded = false;
    this.blogs = result.items;
    this.totalItems = result.totalPages;
    this.isloaded = true;
    //this.current_page = 1;
    (this as any).$Progress.finish();
  }

  @Watch("current_page")
  async onPropertyCurrentPageChanged(value: string, oldValue: string) {
    //this.isloaded = false;
    await this.setTable(this.current_page);
    //this.isloaded = true;
  }

  async handleSearch(searching) {
    (this as any).$Progress.start();
    if (searching.length > 3) {
      this.search = searching;
      BlogService.getListofBlog(1, this.search).then((data) => {
        this.isloaded = false;
        this.blogs = data.items;
        this.totalItems = data.totalPages;
        this.isloaded = true;
      });
    } else if (searching.length == 0) {
      BlogService.getListofBlog(1, this.getBranchId).then((data) => {
        this.isloaded = false;
        this.blogs = data.items;
        this.totalItems = data.totalPages;
        this.isloaded = true;
      });
    }
    (this as any).$Progress.finish();
    //
  }

  removeSpecialChars(str) {
    return str.replace(/[^a-zA-Z0-9 ]/g, "");
  }

  generateURL() {
    var tempVal = this.removeSpecialChars(this.blog.title);
    this.blog.slug = tempVal.replace(" ", "-");
    this.blog.slug = this.blog.slug.replaceAll(" ", "-").toLowerCase();
  }
  onBack() {
    //this.setTable(1)
    this.active = !this.active;
  }

  async onEdit(blogId: number) {
    (this as any).$Progress.start();
    this.blog = await BlogService.getBlogByBlogId(blogId, this.getBranchId);
    //this.pagename = this.blog.post
    this.value = {
      categoryid: this.blog.categoryid,
      category1: this.blog.category,
    };

    if (this.blog.tags != undefined) {
      if (this.blog.tags.length > 0) {
        this.tags = this.blog.tags;
      }
    }

    this.post = this.blog.post;
    this.isPost = this.blog.ispost;
    this.blog.minimumPost = this.blog.blogMinimumPost;
    this.blog.metatags = this.blog.metatag;
    if (this.blog.slug !== undefined) {
      this.generateURL();
    }
    this.blog.slug = this.blog.url;
    // console.log(this.page)
    this.pageHeading = "Update " + this.blog.title;
    this.active = !this.active;
    (this as any).$Progress.finish();
  }

  onOpen() {
    this.pageHeading = "Add New Blog";
    this.active = !this.active;
    this.pageContent = "";
    this.tags = [];
    this.blog = {
      blogid: 0,
      categoryid: 0,
      category: "",
      title: "",
      post: "",
      minimumPost: "",
      ispublished: true,
      metatags: "",
      timstamp: "",
      slug: "",
      ispost: false,
      blogMinimumPost: "",
      metatag: "",
      url: "",
      tags: [],
      metaKey: "",
      metaDiscription: "",
    };
  }

  insertInContent(img: string) {
    console.log(this.post)
    this.post += "<img src='" + img + "'></img>"
    console.log(this.post)
  }

  handleFileUploads(event: any) {
    this.files = event.target.files;

    let formData = new FormData();
    for (var i = 0; i < this.files.length; i++) {
      let file = this.files[i];

      formData.append("files[" + i + "]", file);
    }

    BlogService.uploadBlogImage(formData, this.getBranchId).then(
      (data) => {
        // for (let cu = 0; cu < data.result.length; cu++) {
        //   this.blogImage.push(data.result[cu]);
        // }
        for (let img of data.result) {
          this.blogImage.push(img);
        }
        //this.blogImage.push({imagePath: data.result});
        console.log(data);
      },
      (error) => {}
    );
  }

  async onSubmit() {
    // const isValid = await (this as any).$refs.observer.validate();
    // if (!isValid) {
    //   alert(isValid);
    // }

    // if (this.tags.length == 0)
    // {
    //   this.istagError = true
    //   this.tagError = "Please enter tags"
    //   return false
    // }
    // else{
    //   this.istagError = false
    // }

    (this as any).$Progress.start();
    this.loading = true;
    this.blog.categoryid = this.value.categoryid;
    this.failedMessage = "";
    this.isFailed = false;
    this.blogRequest.blog = this.blog;
    this.blogRequest.tag = this.tags;
    this.blogRequest.blog.ispublished = this.isPost;
    this.blogRequest.blog.post = this.post;
    BlogService.saveBlog(this.blogRequest, this.getBranchId).then(
      (data) => {
        this.loading = false;
        if (data.statusCode == 200) {
          this.setTable(1);
          (this as any).$vs.notify({
            title: "Blog",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
          // this.blog = {
          //   blogid: 0,
          //   categoryid: 0,
          //   category: "",
          //   title: "",
          //   post: "",
          //   minimumPost: "",
          //   ispublished: true,
          //   metatags: "",
          //   timstamp: "",
          //   slug: "",
          //   ispost: false,
          //   blogMinimumPost: "",
          //   metatag: "",
          //   url: "",
          //   tags: [],
          //   metaKey: "",
          //   metaDiscription: "",
          // };
        } else {
          this.isFailed = true;
          this.failedMessage = data.message;
          (this as any).$vs.notify({
            title: "Blog",
            text: data.message,
            color: "danger",
            icon: " highlight_off",
            time: 6000,
          });
        }
        (this as any).$refs.observer.reset();
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "Blog",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
