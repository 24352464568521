import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";
import { ICategory } from "@/models/ICategory";
import { IResponse } from "@/models/IResponse";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;

class CategoryService {
  category!: ICategory;
  lstCategory: ICategory[] = [];
  responsedata: any;

  saveCategory(model: ICategory, key: string): Promise<IResponse> {
    this.responsedata = axios
      .post<IResponse>(API_URL + "category/saveCategory", model, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  deleteCategory(categoryid:number, key: string): Promise<IResponse> {
    this.responsedata = axios
      .post<IResponse>(API_URL + "category/deleteCategory", {Id:categoryid}, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getListofCategory(key: string,search: string = ""): Promise<Result> {
    var URL = API_URL + "category/getallcategories";
    this.responsedata = axios
      .get<IPageResponse>(URL, { headers: auhtHeader(key) })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getCategoryById(categoryId: number, key: string): Promise<ICategory> {
    this.responsedata = axios
      .get<IResponse>(API_URL + "category/getcategoryById?id=" + categoryId, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
}

export default new CategoryService();
